import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function authModule() {
  // const authOrigins = ["https://dev-hub.digitalconversation.net", "http://localhost:3000"];
  let token = '';

  this.setToken = (value) => {
    token = value
  };

  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    }
  );
}

export { axios as Api };
