import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'linear-gradient(to bottom,#f3f3f3,#e2e2e2)'
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  orange: {
    backgroundColor: '#f57e2a'
  },
  logo: {
    width: '40%',
    height: '40%'
  }
}));

export default function Header(props) {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <AppBar color={'default'} position="relative">
        <Toolbar>
          <span>
              <img className={classes.logo} src={props?.orgData?.logo} alt={props?.orgData?.displayName} />
          </span>
        </Toolbar>
      </AppBar>
    </div>
  );
}
