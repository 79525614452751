import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import {Api} from "../Api";
import {AuthContext} from "./authContext";
import MainLayout from "../components/mainLayout";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import {authModule} from '../Api';

export default function OtpChallenge(props) {

  const [otpCode, setOtpCode] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [lastFour, setLastFour] = useState('');
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [wait, setWait] = useState(true);
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    Api.get(
      `/hub/getlast4?s=${props.subscriberKey}&i=${props.dcId}`
    )
      .then((response) => {
        setLastFour(response.data);
      })
      .catch((err) => console.log(err))
  }, [props.subscriberKey, props.dcId]);
  
  const handleOtpChange = (e) => {
    const otpCode = e.target.value;
    setOtpCode(otpCode);
  };

  const handleSendOtpAuth = () => {
    setWait(true);
    Api.get(
      `hub/getotp?s=${props.subscriberKey}&i=${props.dcId}`
    ).then((response) => {
      setOtpSent(true);
    }).catch((err) => {
      setHasError(true);
      setError('Apology but it seems we are experiencing some errors. Please try again.')
    }).finally(() => setWait(false))
  };
  
  const auth = new authModule();

  const handleOtpAuth = () => {
    Api.post(
      `/hub/otp`,
      {
        s: props.subscriberKey,
        i: props.dcId,
        o: otpCode
      }
    ).then((response) => {
        auth.setToken(response.data);
        authContext.setIsOtpAuthenticated(true);
        authContext.setIsAuthenticated(true);
    }).catch((error) => {
      if (error) {
        authContext.setIsOtpAuthenticated(false);
        setHasError(true);
        setError(authContext?.orgData?.loginOtpErrorMsg);
      }
    })
  };

  return (
    <MainLayout orgData={authContext.orgData}>
      {!otpSent && lastFour && <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={10}>
          <Typography variant={'h5'} display={'block'}>
            Verify Your Identity
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'} gutterBottom>
            {`We will send a verification code via SMS to your device ending in ${lastFour}.`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={handleSendOtpAuth}
          >
            Send
          </Button>
        </Grid>
      </Grid>}
      {otpSent && <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant={'h5'} display={'block'}>
            Verify Your Identity
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'} gutterBottom>
            We sent a verification code via SMS to your device ending in
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'} display={'block'}>
            {`xxx-xxx-${lastFour}`}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            value={otpCode}
            error={hasError}
            helperText={error}
            label={'Enter Code'}
            variant={'outlined'}
            focused
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6}}
            autoFocus
            autoComplete='one-time-code'
            onChange={handleOtpChange}
          />
        </Grid>
        <Grid item xs={10}>
          <LoadingButton
            disabled={otpCode?.length < 6}
            loadingPosition={'start'}
            startIcon={<SendIcon />}
            loading={wait}
            variant={'outlined'}
            color={'primary'}
            onClick={handleOtpAuth}
          >
            Continue
          </LoadingButton>
        </Grid>
      </Grid>}
    </MainLayout>
  )
}