import Typography from "@mui/material/Typography";
import * as React from "react";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import {format} from "date-fns";
import {useParams, useOutletContext} from "react-router-dom"
import {useState, useEffect} from "react";
import {Utility} from "../common/utility";

// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import {useTheme} from "@material-ui/core/styles";

export default function MessageBody(props) {

  const [message, setMessage] = useState();
  const [messages,,,onFileClick] = useOutletContext();
  const params = useParams();
  
  const formatSentOnDate = (d) => {
    // const daysAgo = differenceInCalendarDays(new Date(), new Date(d));
    // if (isSmall) {
    //   return isToday(new Date(d)) ?
    //     format(new Date(d), "p") :
    //     daysAgo === 1 ? "Yesterday " : `${daysAgo} days ago`
    // }
    // return isToday(new Date(d)) ?
    //   format(new Date(d), "p") :
    //   format(new Date(d), "PPp")
    if (d) {
      return format(new Date(d), "PPp");
    }
  };

  useEffect(() => {
    // const message = messages;
    // const message = getMessage(params.messageId);
    if (messages && messages.length) {
      setMessage(messages?.find(_ => _._id === params.messageId));
    }
  }, [params.messageId, messages]);
  // const theme = useTheme();
  // const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>
          <Typography variant={'h5'} component={'div'}>
            {message?.subject}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={2} md={1}>
            <Avatar
              alt={message?.userName}
            />
          </Grid>
          <Grid item xs={4} md={9}>
            <Typography style={{fontWeight: 'bold', paddingBottom: '20px', paddingTop: '10px'}} variant={'subtitle2'} gutterBottom
                        component={'div'}>
              {message?.userName}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography style={{fontWeight: 'bold', paddingBottom: '20px', paddingTop: '10px', color: '#5f6368', fontSize: '0.75rem'}} variant={'subtitle2'} gutterBottom
                        component={'div'}>
              {formatSentOnDate(message?.scheduledFor)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph component={'div'}>
            {message?.content}
          </Typography>
        </Grid>
      </Grid>
      {message && message.mediaUrls && message.mediaUrls.length > 0 && message.mediaUrls.map((m, i) => {
        return (<Chip key={i} label={Utility.formatAttachmentLabel(m)} onClick={() => onFileClick(m)}/>)
      })}
    </React.Fragment>
  )

}