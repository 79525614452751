import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Layout from "./layout";
import Alert from "@material-ui/lab/Alert";
import {Api} from '../Api';
import Challenge from "../auth/challenge";
import {AuthContext} from "../auth/authContext";
import Orders from "./orders";
import Refills from "./refills";
import OtpChallenge from "../auth/otp";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";


export default function SecureRx(props) {

  const [state, setState] = useState({items: []});
  const [backdropOpen, setBackdropOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const location = useLocation();

  const [orderData, setOrderData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [comments, setComments] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState('pickup');
  const {type} = props;
  
  const handleChange = (event) => {
    let {items} = state;
    const found = items.findIndex(i => i['id'] === event.target.name);
    if (found > -1) {
      items[found].value = event.target.checked;
    }
    setState({...state, items: items});
  };

  const setQuantity = (event) => {
    let {items} = state;
    const found = items.findIndex(i => i['id'] === event.target.name);
    if (found > -1) {
      let qty = event.target.value;
      const regex = /^[0-9\b]+$/;
      if (!regex.test(qty) && qty !== '') {
        return;
      }
      if (qty === '0') {
        return;
      }
      
      items[found].itemQty = qty;
      const updatedOrder = orderData.orders.findIndex(i => i['_id'] === event.target.name);
      orderData.orders[updatedOrder].itemQty = qty;
      setOrderData(orderData);
    }
    setState({...state, items: items});
  };
  
  const handleDeliveryMethod = (method) => {
    setDeliveryMethod(method);
  };
  
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const dcId = query.get("i");
  const sbnKey = query.get("s");
  
  useEffect(() => {
    const getOrders = () => {
      if (authContext?.isAuthenticated) {
        setBackdropOpen(true);
        Api.get(
          `/orders/get?i=${dcId}&s=${sbnKey}`,
        )
          .then((response) => {
            setOrderData(response.data);
            if (response.data && response.data.orders) {
              setState(state => ({
                ...state, items: response.data?.orders.map(d => {
                  return {id: d._id, value: true, itemQty: d.itemQty}
                })
              }));
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setBackdropOpen(false));
      }
    };
    getOrders();
  }, [authContext?.isAuthenticated, dcId, sbnKey]);

  useEffect(() => {
    const getLogo = () => {
      Api.get(
        `/hub/getlogo?s=${sbnKey}`
      )
        .then((response) => {
          authContext.setOrgData(response.data);
        })
        .catch((err) => console.log(err))
    };
    getLogo();
  }, []);
  

  const handleSubmit = () => {
    const subscriberPhone = orderData.orders[0].subscriberPhone;
    orderData.orders = orderData.orders.map(o => {
      const item = state.items.find(f => f.id === o._id);
      if (comments) {
        o.comments = comments;
      }
      if (item.value === true) {
        o.orderStatus = 'OrderYes'
        o.deliveryMethod = deliveryMethod;
      } else {
        o.orderStatus = 'OrderNo'
      }
      if (item.itemQty >= 0){
        o.itemQty = item.itemQty;
      }
      return o;
    });
    Api.put(
      `/orders/update?subscriberkey=${sbnKey}&subscriberphone=${subscriberPhone}`,
      orderData
    ).then(() => setSubmitted(true));
  };

  return (
    <div>
      {!authContext?.isDobAuthenticated && authContext.orgData?.loginType.includes("DOB") && <Challenge subscriberKey={sbnKey} dcId={dcId}/>}
      {((authContext?.isDobAuthenticated && !authContext?.isOtpAuthenticated && authContext.orgData && authContext.orgData?.loginType.includes("OTP")) ||
        (!authContext?.isOtpAuthenticated && authContext.orgData?.loginType === "OTP")) && 
        <OtpChallenge subscriberKey={sbnKey} dcId={dcId} />}
      {authContext?.isAuthenticated && orderData && orderData.orders && !submitted &&
        <Layout orgData={authContext.orgData}>
          <Backdrop
            sx={{ color: '#fff', zIndex: 1000 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={2}>
            {type === 'orders' && <Orders
              initialMessage={authContext?.orgData?.ordersInitialMessage}
              orderData={orderData}
              items={state.items}
              handleDeliveryMethod={handleDeliveryMethod}
              deliveryMethod={deliveryMethod}
              handleChange={handleChange}
              setQuantity={setQuantity}
            />}
                
            {type === 'refills' && <Refills
              initialMessage={authContext?.orgData?.ordersInitialMessage}
              orderData={orderData}
              items={state.items}
              handleDeliveryMethod={handleDeliveryMethod}
              deliveryMethod={deliveryMethod}
              handleChange={handleChange} 
            />}
                
            <Grid item xs={12} />
            
            <Grid item xs={12}>
              <Typography variant={'subtitle1'}>
                Please let us know if there is anything else we can help you with!
              </Typography>
              <TextField 
                variant={'outlined'}
                minRows={3}
                multiline 
                fullWidth
                onChange={(e) => setComments(e.target.value)}
              />
            </Grid>
          
            <Grid item xs={12}>
              <Paper style={{"padding": "15px"}}>
                <div><Typography variant={'body2'}>{`${orderData?.firstName} ${orderData?.lastName}`}</Typography></div>
                <div><Typography variant={'body2'}>{orderData?.address1}</Typography></div>
                <div><Typography
                  variant={'body2'}>{`${orderData?.city} ${orderData?.state} ${orderData?.postalCode}`}</Typography></div>
              </Paper>
            </Grid>
            <Grid item container justifyContent={'center'}>
              <Button variant={'outlined'} color={'primary'} onClick={handleSubmit} type={'submit'}>Submit</Button>
            </Grid>
          </Grid>
        </Layout>
      }
      {authContext?.isAuthenticated && orderData && !orderData.orders &&
        <Layout orgData={authContext.orgData}>
          <Alert severity={'info'}>
            You don't have any pending orders. Thank you.
          </Alert>
        </Layout>
      }
      {authContext?.isAuthenticated && submitted &&
        <Layout orgData={authContext.orgData}>
          <Alert severity={'info'}>
            {authContext?.orgData.ordersAfterSubmitMessage}
          </Alert>
        </Layout>
      }
    </div>
  )
}
