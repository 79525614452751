import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import React, {useContext, useState} from "react";
import {parse, isValid, format} from "date-fns";
import {AuthContext} from "./authContext";
import {Api, authModule} from '../Api';
import MainLayout from "../components/mainLayout";

export default function Challenge(props) {

  const [authError, setAuthError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [counter, setCounter] = useState(0);
  const [dob, setDob] = useState('');
  const authContext = useContext(AuthContext);

  const auth = new authModule();

  const handleDob = (e) => {
    let dob = e.target.value;
    const regex = /^[0-9\b/]+$/;
    const length = dob.length;

    if (dob === '' || regex.test(dob)) {
      if (dob.length === 2 || dob.length === 5) {
        if (dob.trim().length > counter)
          dob += '/';
      }
      if (dob.length > 10) {
        dob = dob.substring(0, 10);
      }
      setCounter(length);
      setDob(dob.trim());
    }
  };

  const handleAuth = (e) => {
    e.preventDefault();
    const parsedDob = parse(dob, 'P', new Date());

    if (!isValid(parsedDob)) {
      setAuthError(true);
      setErrorText(authContext?.orgData?.loginDobErrorMsg);
      authContext.setIsAuthenticated(false);
    } else {
      Api.post(
        `/hub/dob`,
        {
          s: props.subscriberKey,
          d: format(parsedDob, "yyyy-MM-dd"),
          i: props.dcId
        }
      ).then((response) => {
        authContext.setIsDobAuthenticated(true);
        if (authContext.orgData.loginType === "DOB") {
          auth.setToken(response.data);
          authContext.setIsAuthenticated(true);
        }
      }).catch(e => {
          setErrorText(authContext?.orgData?.loginDobErrorMsg);
        });
    }
  };
  
  return (
    <MainLayout orgData={authContext.orgData}>
      <form onSubmit={handleAuth} autoComplete={'off'}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant={'subtitle1'}>
              {authContext?.orgData?.loginDobPrompt}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              error={authError}
              helperText={errorText}
              value={dob}
              placeholder={'mm/dd/yyyy'}
              variant={'outlined'}
              focused
              autoFocus
              onChange={handleDob}
            />
          </Grid>
          <Grid item xs={10} md={10}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={handleAuth}
              type={'submit'}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  )
}