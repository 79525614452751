import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import Container from "@material-ui/core/Container";
import {AuthContext} from "../auth/authContext";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Layout(props) {
  const authContext = useContext(AuthContext);
  
  return (
    <div className={'content'}>
      <Header orgData={authContext.orgData} />
      <Container className={'fill'}>
          {props.children}
      </Container>
      <Footer orgData={authContext.orgData} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any
};
