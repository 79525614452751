import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React, {Fragment} from "react";

export default function Suggestions(props) {
  const {items, suggestions, handleChange} = props;
  return (
    <Fragment>
      {suggestions && suggestions.map((s, i) => (
        <Grid key={i} item xs={10}>
          <Grid item xs={10}>
            <Typography variant={'subtitle1'}>
              Suggested products to add to your order
            </Typography>
          </Grid>
          <Paper style={{paddingLeft: '20px', paddingTop: '5px'}}>
            <Grid container spacing={2}>
              <Grid item xs={9} md={3}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      id={'first'}
                      control={<Checkbox checked={items[s._id]}
                                         onChange={handleChange}
                                         name={s._id}
                      />}
                      label={s.itemName}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3} md={9}>
                <Typography style={{marginTop: '6px'}}>Qty: {s.itemQty}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>))}
    </Fragment>
  )
}