
export class Utility {
  static generateRandomString(length) {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
  }

  static formatAttachmentLabel = (attachmentName) => {
    let name;
    if (attachmentName?.split('/').length === 3) {
      name = attachmentName.split('/')[2];
      if (name) {
        name = name.substring(9, name.length);

        if (name.length > 25) {
          name = name.substring(0, 10) + '...';
        }
      }
    }
      
    return name;
  };

}