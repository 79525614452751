import React from 'react';
import Grid from "@material-ui/core/Grid";

export default function Footer(props) {
  return (
    <Grid container justifyContent={'center'} style={{flex: 'none', marginTop: '5px'}}>
      <Grid item>
        <footer className="txt-color-white">{`${props?.orgData?.displayName || ''} ©${new Date().getFullYear()}`}</footer>
      </Grid>
    </Grid>
  );
}
