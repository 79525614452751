import React, {useContext, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Api} from "../Api";
import {AuthContext} from "../auth/authContext";
import {useNavigate, useOutletContext} from "react-router-dom";
import {Utility} from "../common/utility";
import Grid from "@material-ui/core/Grid";
import {DropzoneDialog} from "material-ui-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Compose(props) {

  const [open, setOpen] = useState(true);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const maxFileSize = 100000000; // in bytes
  const acceptedFiles=['image/*', '.pdf', '.doc', '.docx', '.xslx', '.mov', '.mp4', '.mp3', '.avi', '.wmv'];
  const dropzoneText = `Drag and drop a file here or click. Only images, ${acceptedFiles.slice(1, acceptedFiles.length-1).join(", ")} files are accepted.`
  
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [, subscriberKey, dcId ] = useOutletContext();

  const handleClose = () => {
    setOpen(false);
    navigate({
      pathname: '/mail/messages',
      search: `?s=${subscriberKey}&i=${dcId}`,
    });
  };
  
  const onUploadFiles = async(mediaUrlsDictionary) => {
    try {
      let url = `hub/fileupload`;
      let uploads = [];
      for (const key in mediaUrlsDictionary) {
        const options = {
          fileKey: key,
          contentType: mediaUrlsDictionary[key].type,
          op: "up",
        };
        uploads.push(Api.post(url, options)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            return putFileAsync(data.url, mediaUrlsDictionary[key]).then(() => {

            });
          })
          .catch(e => {
            console.log(e);
          }));
      }
      
      return await Promise.all(uploads);
    } catch (e) {}
  };

  const putFileAsync = (url, file) => {
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: file,
    });
  };
  
  const handleSend = () => {
    let body = {
      's': subscriberKey,
      'i': dcId,
      'subject': subject,
      'content': message
    };

    let mediaUrls;
    let mediaUrlsDictionary = {};
    if (files?.length > 0) {
      const folderName = `${authContext.orgData.subscriberKey}/${dcId}`;
      mediaUrls = files.map((f) => {
        let fileKey = `${Utility.generateRandomString(8)}-${f.name}`;
        if (folderName) {
          fileKey = `${folderName}/${fileKey}`;
          mediaUrlsDictionary[fileKey] = f;
        }
        return fileKey;
      });
      body = {...body, mediaUrls};
    }
    
    Api.post(
      `/hub/interaction`,
      body
    ).then(() => {
      if (files?.length > 0) {
        onUploadFiles(mediaUrlsDictionary);
      }
    }).catch(ex => {
        handleClose();
    }).finally(() => {
        authContext.setSuccess(true);
        setOpen(false);
        navigate(`/mail?a=load&s=${authContext.userContext.subscriberKey}&i=${authContext.userContext.dcId}`);
    });
  };
  
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Compose</DialogTitle>
        <DialogContent sx={{position: 'relative'}}>
          <Grid container alignItems={'center'} justifyContent={'space-between'} spacing={2}>
            <Grid item xs={10}>
              <TextField 
                autoFocus
                margin="dense"
                id="messageSubject"
                label="Subject"
                fullWidth
                required
                variant="standard"
                onChange={(_) => setSubject(_.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip title='Attach'>
                <Button
                  aria-label={'Attach'}
                  role={'button'}
                  size={'small'}
                  onClick={() => setDropzoneOpen(true)}>
                  <AttachFileIcon style={{cursor: 'pointer'}} />
                </Button>
              </Tooltip>
              <DropzoneDialog
                acceptedFiles={acceptedFiles}
                cancelButtonText={"cancel"}
                dropzoneText={dropzoneText}
                submitButtonText={"submit"}
                maxFileSize={maxFileSize}
                open={dropzoneOpen}
                onClose={() => setDropzoneOpen(false)}
                onSave={(files) => {
                  setFiles(files);
                  setDropzoneOpen(false);
                }}
                showPreviews
                useChipsForPreview
                showFileNamesInPreview
              />
            </Grid>
            <Grid item xs={12} flexGrow={4}>
              <TextField
                autoComplete={false}
                multiline
                required
                InputProps={{
                  disableUnderline: true
                }}
                id="messageContent"
                label="Content"
                fullWidth
                variant="standard"
                onChange={(_) => setMessage(_.target.value)}
              />
            </Grid>
          </Grid>
          <Box sx={{position: 'absolute', bottom: 0}}>
            {files && files.map((f, i) => <Chip key={i} label={f.name} />)}
          </Box>
        </DialogContent>
        <DialogActions>
          {/*<input type="file" name="file" onChange={onUploadFiles} />*/}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSend} disabled={subject === null || message === null || subject?.length === 0 || message?.length === 0} >Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}