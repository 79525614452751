import React, {Fragment, useContext, useEffect, useState} from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MedicationIcon from "@mui/icons-material/Medication";
import PharmacyIcon from "@mui/icons-material/LocalPharmacy";
import ScienceIcon from "@mui/icons-material/Science";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import {useNavigate, Outlet, useLocation} from "react-router-dom";
import {Api} from "../Api";
import {AuthContext} from "../auth/authContext";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SendIcon from '@mui/icons-material/Send';
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'linear-gradient(to bottom,#f3f3f3,#e2e2e2)'
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  orange: {
    backgroundColor: '#f57e2a'
  },
  logo: {
    width: '25%',
    height: '25%'
  }
}));

export default function Inbox(props) {

  const [messages, setMessages] = useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const containerWindow  = props.window;
  const classes = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const action = query.get("a");

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const getMessages = () => {
    setBackdropOpen(true);
    Api.get(
      `/hub/getmessages?s=${authContext.userContext.subscriberKey}&i=${authContext.userContext.dcId}`
    )
      .then((response) => {
        setMessages(response.data);
        navigate(`/mail/messages?s=${authContext.userContext.subscriberKey}&i=${authContext.userContext.dcId}`);
      })
      .catch((err) => {

      })
      .finally(() => setBackdropOpen(false));
  };

  useEffect(() => {
    
    if (action === 'load') {
      getMessages();
    }
  }, [action]);

  useEffect(() => {
    getMessages();
  }, []);
  
  useEffect(() => {
    if(authContext.success) {
      setOpenSnackbar(authContext.success);
    }
  }, [authContext.success]);
  
  const goToInbox = () => {
    setMobileOpen(false);
    navigate({
      pathname: '/mail/messages',
      search: `?s=${authContext.userContext.subscriberKey}&i=${authContext.userContext.dcId}`,
    });
  };

  const goToOutbox = () => {
    setMobileOpen(false);
    navigate({
      pathname: '/mail/outbox',
      search: `?s=${authContext.userContext.subscriberKey}&i=${authContext.userContext.dcId}`,
    });
  };

  const signDownload = (fileKey) => {
    let url = `hub/fileupload`;
    const options = {
      fileKey: fileKey,
      op: 'down'
    };
    return Api.post(url, options)
      .then((response) => {
        return response.data
      })
      .then((data) => {
        // console.log(data);
        window.location.assign(data.signedRequest);
      });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  
  const container = containerWindow !== undefined ? () => containerWindow().document.body : undefined;

  const drawer = (
    <div>
      <Toolbar>
        <img className={classes.logo} src={authContext?.orgData?.logo} alt={authContext?.orgData?.displayName} />
      </Toolbar>
      <Divider/>
      <List>
        <ListItem button onClick={() => goToInbox()}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='Inbox'  />
        </ListItem>
        <ListItem button onClick={() => goToOutbox()}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary='Sent' />
        </ListItem>
        <ListItem button onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <ScienceIcon />
          </ListItemIcon>
          <ListItemText primary='Labs' />
        </ListItem>
        <ListItem button onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <MedicationIcon />
          </ListItemIcon>
          <ListItemText primary='Rx Refills' />
        </ListItem>
        <ListItem button onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <PharmacyIcon />
          </ListItemIcon>
          <ListItemText primary='Medical Orders' />
        </ListItem>
      </List>
      <Divider/>
    </div>
  );

  return (
    <Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar
          position="fixed"
          sx={{
            width: {sm: `calc(100% - ${drawerWidth}px)`},
            ml: {sm: `${drawerWidth}px`},
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2, display: {sm: 'none'}}}
            >
              <MenuIcon/>
            </IconButton>
            
            <Typography variant="h6" noWrap component="div">
              {authContext?.orgData?.displayName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {xs: 'block', sm: 'none'},
              '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: {xs: 'none', sm: 'block'},
              '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          height={'85vh'}
          component="main"
          sx={{flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }}}
        >
          <Toolbar/>
          <Outlet context={[messages, props.subscriberKey, props.dcId, signDownload]} />
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            TransitionComponent={SlideTransition}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              Message sent successfully!
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Fragment>
  )
}