import React, {useContext, useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import Layout from "./layout";
import {Api} from "../Api";
import {AuthContext} from "../auth/authContext";

export default function Index(props) {
  
  const authContext = useContext(AuthContext);
  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const subscriberKey = query.get("s");
  const dcId = query.get("i");

  useEffect(() => {
    const getLogo = () => {
      Api.get(
        `/hub/getlogo?s=${subscriberKey}`
      )
        .then((response) => {
          authContext.setOrgData(response.data);
          authContext.setUserContext({subscriberKey, dcId})
        })
        .catch((err) => console.log(err));
    };
    getLogo();
  }, []);
  
  
  return (
    <Layout subscriberKey={subscriberKey} dcId={dcId} />
  );
}

// http://localhost:3000/inbox?s=demo-hub-d&i=60ff156ad7a5c20a8cfba530