import './App.css';
import SecureRx from "./rx";
import Inbox from "./inbox";
import MessageBody from "./inbox/messageBody";
import React from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import {AuthProvider} from "./auth/authContext";
import MessageList from "./inbox/messageList";
import Compose from "./inbox/compose";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path={'orders'} element={<SecureRx type={'orders'} />} />
          <Route path={'refills'} element={<SecureRx type={'refills'} />} />
          <Route path={'mail'} element={<Inbox />}>
            <Route path={'messages'} element={<MessageList type={'inbox'} />} />
            <Route path={'outbox'} element={<MessageList type={'outbox'}/>} />
            <Route path={'compose'} element={<Compose />} />
            <Route
              path={':messageId'}
              element={ <MessageBody /> } />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
