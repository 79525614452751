import * as React from 'react';
import {useContext} from "react";
import {AuthContext} from "../auth/authContext";
import Challenge from "../auth/challenge";
import OtpChallenge from "../auth/otp";

import Inbox from "./inbox";

export default function Layout(props) {
  const authContext = useContext(AuthContext);
  return (
    <React.Fragment>
      {!authContext?.isDobAuthenticated && authContext.orgData?.loginType.includes("DOB") && 
        <Challenge subscriberKey={props.subscriberKey} dcId={props.dcId}/>}
      {((authContext?.isDobAuthenticated && !authContext?.isOtpAuthenticated && authContext.orgData && authContext.orgData?.loginType.includes("OTP")) ||
        (!authContext?.isOtpAuthenticated && authContext.orgData?.loginType === "OTP")) &&
        <OtpChallenge subscriberKey={props.subscriberKey} dcId={props.dcId} loginType={authContext.orgData?.loginType} />}
      
      {/*{!authContext?.isAuthenticated && authContext.orgData && authContext.orgData.loginType.includes("DOB") && 
        <Challenge subscriberKey={props.subscriberKey} dcId={props.dcId} />}
      {authContext?.isAuthenticated && !authContext.isOtpAuthenticated && authContext.orgData.loginType.includes("OTP") && 
        <OtpChallenge subscriberKey={props.subscriberKey} dcId={props.dcId}/>}*/}
      {authContext && authContext.isAuthenticated && <Inbox subscriberKey={props.subscriberKey} dcId={props.dcId} />}
    </React.Fragment>
  )}

