import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {format} from 'date-fns';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Toolbar from "@material-ui/core/Toolbar";
import {AuthContext} from "../auth/authContext";
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Typography from "@material-ui/core/Typography";
import {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import AttachFileIcon from '@mui/icons-material/AttachFile'
import useDetectScroll, { Direction } from "@smakss/react-scroll-direction";
import {useState, useEffect} from "react";
import Chip from "@material-ui/core/Chip";
import Paper from "@mui/material/Paper";
import {Utility} from "../common/utility";

const columns = [

  {field: 'sender', headerName: '', flex: 0.2},
  {field: 'subject', headerName: '', flex: 0.4},
  {
    field: 'sentOn',
    headerName: '',
    type: 'date',
    flex: 0.3,
  },
  {
    field: 'mediaUrls',
    headerName: '',
    flex: 0.1,
    renderCell: renderAttachment
  }
];

function renderAttachment(props) {
  const { value } = props;
  return (
    value?.length > 0 && <AttachFileIcon />
  )
}

export default function MessageList(props) {

  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [messages] = useOutletContext();
  const [fabVariant, setFabVariant] = useState('');
  const [fabText, setFabText] = useState(null);
  const scrollDir = useDetectScroll({});

  const goToCompose = () =>
    navigate({
      pathname: '/mail/compose',
      search: `?s=${authContext.userContext.subscriberKey}&i=${authContext.userContext.dcId}`,
    });
  
  const onRowClick = (gridRowParams, event, details) => {
    const {row} = gridRowParams;
    navigate(`/mail/${row.id}`);
  };
  
  const onMobileClick = (id) => {
    navigate(`/mail/${id}`);
  };
  
  useEffect(() => {
    if (scrollDir === Direction.Down) {
      setFabText('');
      setFabVariant('circular');
    } else if (scrollDir === Direction.Up) {
      setFabText('Compose');
      setFabVariant('extended');
    }
  }, [scrollDir]);
  
  const getRowClass = (gridRowParams) => {
    const {row} = gridRowParams;
    if (!row.read) {
      return 'a1';
    }
    return '';
  };

  const processMessages = (messages) => {
    if (messages) {
      let result = props.type === 'outbox' ? messages.filter(m => m.direction === 'INBOUND') 
        : messages.filter(m => m.direction === 'OUTBOUND');
      result = result.sort((a,b) => (a.scheduledFor < b.scheduledFor) ? 1 : ((b.scheduledFor > a.scheduledFor) ? -1 : 0));
      return result.map(m => {
        return {
          id: m._id,
          sender: m.userName,
          subject: m.subject,
          sentOn: format(new Date(m.scheduledFor), "Pp"),
          mediaUrls: m.mediaUrls
        }
      });
    }
    return [];
  };
  
  const HeaderToolbar = () => {
    return <Toolbar> 
      <Typography variant={'caption'}>{authContext.orgData?.inboxHelpMessage}</Typography>
    </Toolbar>
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box>
      {isSmall && <Typography gutterBottom paragraph variant={'h6'}>{props.type === 'inbox' ? 'Inbox' : 'Sent'}</Typography>}
      <Grid container spacing={3}>
        {isSmall && processMessages(messages).map(m =>
          <Paper
            key={m.id}
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#F3F6F9',
            }}
          >
            <Grid item container onClick={() => {onMobileClick(m.id)}}>
              <Grid container direction={'row'}>
                <Grid item xs={2}>
                  <Avatar alt={m.sender.substring(0,1)} />
                </Grid>
                <Grid container item xs={10}>
                  <Grid item xs={6} sm={3}>
                    <Typography variant={'subtitle1'}>{m.sender}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <Typography variant={"subtitle2"}>{m.sentOn}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    {m.subject}
                  </Grid>
                  {m.mediaUrls?.length > 0 && <Grid item xs={12} sm={7}>
                    <Chip label={Utility.formatAttachmentLabel(m.mediaUrls[0])} />
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      
      {!isSmall && <DataGrid
        rows={processMessages(messages)}
        autoHeight
        onRowClick={onRowClick}
        getRowClassName={getRowClass}
        headerHeight={0}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar: HeaderToolbar
        }}
      />}
      <Fab
        sx={{ position: 'fixed', bottom: 50, right: 30, top: 'auto', left: 'auto' }}
        variant={fabVariant}
        size="small"
        onClick={goToCompose}
        color="primary"
        aria-label="add">
        <EditIcon />
        {fabText}
      </Fab>
    </Box>
  );
}
