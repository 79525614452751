import React, {Fragment, useState} from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Suggestions from "./suggestions";
import Delivery from "./delivery";
import Paper from "@mui/material/Paper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.8rem'}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Refills(props) {
  const {orderData, items, handleChange, initialMessage, handleDeliveryMethod, deliveryMethod} = props;
  const suggestions = orderData && orderData.orders && orderData.orders.some(o => o.orderType === 'Suggestion') &&
    orderData.orders.filter(o => o.orderType === 'Suggestion');
  
  const [expanded, setPanelExpanded] = useState('');

  const handleAccordion = (panel) => (event, newExpanded) => {
    setPanelExpanded(newExpanded ? panel : false);
  };

  return (
    <Fragment>
      <Grid item xs={10}>
        <Typography variant={'subtitle1'}>
          {initialMessage}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        {orderData?.orders && orderData?.orders.filter(o => o.orderType === 'Pharmacy').map(o => (
          <div key={o._id}>
            <Paper
              key={o._id}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <Grid container >
                <Grid item>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        id={o._id}
                        control={<Checkbox checked={items[o._id]}
                                           defaultChecked
                                           onChange={handleChange}
                                           name={o._id}/>}
                        label={o.itemName}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography variant="subtitle1" color="text.secondary">
                        Qty: {o.itemQty}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Accordion expanded={expanded === o._id} onChange={handleAccordion(o._id)}>
              <AccordionSummary style={{fontSize: 'small'}}>
                Click to see details
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant={'caption'} display={'block'} gutterBottom>Doctor: {o.doctor}</Typography>
                <Typography variant={'caption'} display={'block'} gutterBottom>Days
                  Supply: {o.daysSupply}</Typography>
                <Typography variant={'caption'} display={'block'} gutterBottom>RX
                  Number: {o.rxNumber}</Typography>
                <Typography variant={'caption'} display={'block'} gutterBottom>Sig: {o.sig}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>))
        }
      </Grid>
      <Suggestions suggestions={suggestions} handleChange={handleChange} />
      <Delivery handleDeliveryMethod={handleDeliveryMethod} deliveryMethod={deliveryMethod} />
    </Fragment>
  )
}