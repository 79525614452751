import React from 'react';
import PropTypes from 'prop-types';
import Container from "@material-ui/core/Container";
import Header from "./header";
import Footer from "./footer";

export default function MainLayout(props) {

  return (
    <div className={'content'}>
      <Header orgData={props.orgData} />
      <Container className={'fill'}>
        {props.children}
      </Container>
      <Footer orgData={props.orgData} />
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any
};
