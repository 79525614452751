import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

export default function Delivery(props) {
  return (
    <Fragment>
      <Grid spacing={1} container style={{marginTop: '5px', padding: '8px'}}>
        <Grid>
          <Typography variant={'subtitle1'}>
            Tell us how you want to get these items.
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <RadioGroup 
            value={props.deliveryMethod} 
            onChange={(e) => {
              props.handleDeliveryMethod(e.target.value);
            }}
            name={'delivery-method-group'}
          >
            <FormControlLabel value={'pickup'} label={'Pickup'} control={<Radio />} />
            <FormControlLabel value={'mail'} label={'Mail'} control={<Radio />} />
            <FormControlLabel value={'deliver'} label={'Deliver'} control={<Radio />} />
            <FormControlLabel value={'text'} label={'Please Text Me'} control={<Radio />} />
          </RadioGroup>
        </Grid>
      </Grid>   
    </Fragment>
  )
}