import React, {createContext, useState} from "react";

export const AuthContext = createContext([]); 

export const AuthProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOtpAuthenticated, setIsOtpAuthenticated] = useState(false);
  const [isDobAuthenticated, setIsDobAuthenticated] = useState(false);
  const [orgData, setOrgData] = useState(null);
  const [userContext, setUserContext] = useState(null);
  const [success, setSuccess] = useState(false);
  
  return (
    <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, 
      orgData, setOrgData, 
      isOtpAuthenticated, setIsOtpAuthenticated,
      isDobAuthenticated, setIsDobAuthenticated,
      userContext, setUserContext,
      success, setSuccess
    }}>
      {props.children}
    </AuthContext.Provider>
  );
};
