import React, {Fragment, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Paper from '@mui/material/Paper';
import Typography from "@material-ui/core/Typography";
import Suggestions from "./suggestions";
import Delivery from "./delivery";
import TextField from "@mui/material/TextField";

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function Orders(props) {
  const {orderData, items, handleChange, initialMessage, handleDeliveryMethod, deliveryMethod, setQuantity} = props;
  const suggestions = orderData && orderData.orders && orderData.orders.some(o => o.orderType === 'Suggestion') &&
    orderData.orders.filter(o => o.orderType === 'Suggestion');
  return (
    <Paper elevation={2} sx={{ padding: 1}}>
      <Grid item xs={12}>
        <Typography variant={'subtitle1'}>
          {initialMessage}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {orderData?.orders && orderData?.orders.filter(o => o.orderType === 'Medical').map(o => (
          <Paper
            key={o._id}
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#F3F6F9',
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      id={o._id}
                      control={<Checkbox defaultChecked
                                         onChange={handleChange}
                                         name={o._id}/>}
                      label={o.itemName}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <TextField
                      name={o._id}
                      type="number"
                      value={o.itemQty}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!items.find((i) => i.id === o._id)?.value}
                      label={'Qty'}
                      inputProps={{ step: 1, min:0, inputMode: 'numeric', pattern: '[0-9]*' }}
                      size={'small'}
                      sx={{ width: '60px', height: '30px'}}
                      onChange={setQuantity}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  {(o.itemCost && parseInt(o.itemCost)) > 0 && 
                    <Typography variant="subtitle1" component="div">
                      Unit Price: {USDollar.format(o.itemCost)}
                    </Typography>
                  }
                  {(!o.itemCost || !parseInt(o.itemCost)) &&
                    <Typography variant="subtitle2" component="div">
                      Check with us for price!
                    </Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          ))}
      </Grid>
      <Suggestions suggestions={suggestions} handleChange={handleChange} />
      <Delivery handleDeliveryMethod={handleDeliveryMethod} deliveryMethod={deliveryMethod} />
    </Paper>
  )
}